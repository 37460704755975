import { isError } from 'lodash';
import React, { useCallback } from 'react';
import { MFE_APP_NAMES, getMfeApp } from '.';
import { getHistory } from '../history.service';
import { getLogger } from './utils';

const logger = getLogger('AnalyticsComp');

export const AnalyticsComp = (): JSX.Element => {
    const history = getHistory();

    const getBffUrl = window.Props?.bffContextRoot as string;

    const getAccessToken = useCallback(async () => {
        const userManager = window.Props?.userManager;

        if (!userManager) {
            logger.error('User manager not found in window.Props');
            return null;
        }
        const user = await userManager.getUser();
        return user?.access_token;
    }, []);

    React.useEffect(() => {
        logger.debug('AnalyticsComp mounted');
        const urpApp = getMfeApp(MFE_APP_NAMES.WK_ELM_URP_APP);

        urpApp.mount({ defaultHistory: history, bffBaseURL: getBffUrl, getAccessToken }).catch((e) => {
            if (isError(e)) {
                logger.error(`Error mounting ${MFE_APP_NAMES.WK_ELM_URP_APP}`, e);
            }
        });

        return (): void => {
            urpApp.unmount().catch((e) => {
                logger.error(`new log Error unmounting ${MFE_APP_NAMES.WK_ELM_URP_APP}`, e);
                if (isError(e)) {
                    logger.error(`Error unmounting ${MFE_APP_NAMES.WK_ELM_URP_APP}`, e);
                }
            });
        };
    }, []);
    return <div id="wk-elm-urp-app"></div>;
};
