import { makeStyles } from '@mui/styles';
import React from 'react';
import { Route } from 'react-router-dom';
import { useUUIHistory } from '../../../hooks';
import { InitializeAppCallback, CustomComponents } from '../../../initialize';
import { AnalyticsComp } from '../../../mfe';
import { Announcements } from '../../announcements';
import {
    BlockUi,
    FullScreenOverlayDialog,
    GlobalDragAndDropHandler,
    UuiAppBar,
    UUIDialogs,
    UuiPage,
    ScreenType,
} from '../../core';
import { Favorites, FavoritesBar } from '../../favorites';
import { MainMenu } from '../../menu';
import { AppTheme } from '../appTheme';
import { InactivityAlertDialog } from '../inactivityAlertDialog';
import { useAppInitializer } from './useAppInitializer.hook';
import { useMessageBus } from './useMessageBus.hook';

export enum AppRoutes {
    EndUser = '/enduser',
    About = '/about',
    Contacts = '/contacts',
    Favorites = '/favorites',
    Analytics = '/urp',
}

const useStyles = makeStyles<AppTheme>((theme) => ({
    app: {
        ...theme.styles.appContainer,
        backgroundColor: theme.palette.common.white,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: 0,
        overflowY: 'auto',
    },
    favoriteBar: {
        borderBottom: '1px solid #A2A2A2',
    },
}));

export type AppContainerProps = {
    customComponents: Omit<CustomComponents, 'AppProvider'>;
    initializeAppCallback?: InitializeAppCallback;
};

export const AppContainer: React.FC<AppContainerProps> = ({ customComponents, initializeAppCallback }) => {
    const classes = useStyles();
    const { CustomAboutComponent, CustomContactsComponent } = customComponents;
    const { initialized } = useAppInitializer({ initializeAppCallback });
    useMessageBus();
    const isGridScreen = useUUIHistory().location.pathname.includes(ScreenType.GridScreen);

    const renderLoading = () => (
        <>
            <FullScreenOverlayDialog />
            <BlockUi />
        </>
    );

    const renderApplication = () => (
        <div id="uuiAppContainer" className={classes.app}>
            {!isGridScreen && <UuiAppBar />}

            <div className={classes.container}>
                {!isGridScreen && <MainMenu />}
                <div className={classes.content}>
                    <FavoritesBar floating classes={{ root: classes.favoriteBar }} />
                    <Route path={AppRoutes.Favorites} component={Favorites} />
                    <Route path={AppRoutes.EndUser} component={UuiPage} />
                    <Route path={AppRoutes.About} component={CustomAboutComponent} />
                    <Route path={AppRoutes.Contacts} component={CustomContactsComponent} />
                    <Route path={AppRoutes.Analytics} component={AnalyticsComp} />
                    <UUIDialogs />
                    <BlockUi />
                    <GlobalDragAndDropHandler />
                    <InactivityAlertDialog />
                    <Announcements />
                </div>
            </div>
        </div>
    );

    return <>{initialized ? renderApplication() : renderLoading()}</>;
};
